export default {
  "header": "_header_1l0xa2",
  "current-folder": "_current-folder_1l0xa2",
  "file-browser-list": "_file-browser-list_1l0xa2",
  "uploading": "_uploading_1l0xa2",
  "files-list": "_files-list_1l0xa2",
  "column": "_column_1l0xa2",
  "sort-by": "_sort-by_1l0xa2",
  "load-more": "_load-more_1l0xa2",
  "load-more-button": "_load-more-button_1l0xa2"
};
